import React, { useState, useEffect } from 'react';
import { 
    Modal,
    Form,
    Row,
    Col,
    Input,
    notification,
} from 'antd';
import { Button } from "Components/ui/button"; 
import { 
    submitAffiliatesPayout,
} from "../../../API";

const WithdrawAffiliatePortalFormModal = ({isModalOpen, closeModal}) => { 
    const [formWithdraw] = Form.useForm();
    const [toast, contextHolder] = notification.useNotification(); 

    const onSubmitWithdrawForm = async (values) => {
        let postObj = {
            amount: values?.withdraw_amount ?? 0,
        }   

        try {
            let result = await submitAffiliatesPayout(postObj);
            if (result) {
                toast.success({
                    message: "",
                    description: "Submit Withdraw Request Successfully.",
                    placement: "top",
                });
                handleResetFormWithdraw();
                closeModal(true);
            }
        } catch(error) {
            toast.error({
                message: "",
                description: error?.response?.data?.message ?? "Create Withdraw Failed!",
                placement: "top",
            });
            console.error('Error submit form withdraw:', error);
        }
    }

    const handleResetFormWithdraw = () => {
        formWithdraw.resetFields([
            'withdraw_amount', 
        ]);
    }

    const handleOnInputWithdrawAmount = (e) => {
        // Allow only numeric input by removing non-numeric characters
        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
    };

    useEffect(() => {
        if (isModalOpen) {
            handleResetFormWithdraw();
        }
    }, [isModalOpen]); 

    return (
        <>
            {contextHolder}
            <Modal 
                title={
                    <>
                        <div>Withdraw</div>
                        <hr className='my-2'/>
                    </>
                }
                open={isModalOpen}  
                onCancel={() => closeModal(false)}
                footer={null} 
                maskClosable={false}
            >
                <Form
                    form={formWithdraw}
                    name="withdraw_form"
                    onFinish={(values) => onSubmitWithdrawForm(values, formWithdraw)}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    requiredMark={false} 
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="withdraw_amount" 
                                label={<span>Withdraw Amount<span style={{ color: 'red' }}> *</span></span>} 
                                rules={[
                                    {
                                        required: true,
                                        message: "Withdraw Amount is required",
                                    },
                                    {
                                        validator: (_, value) => {
                                            return new Promise((resolve, reject) => {
                                                if (!value) {
                                                    resolve(); // Allow empty value
                                                } else {
                                                    const floatValue = parseFloat(value);
                                                    if (floatValue < 100) {
                                                        reject('Minimum $100');
                                                    } else {
                                                        const regex = /^\d*\.?\d+$/;
                                                        if (!(regex.test(value))) {
                                                            reject('Please enter a valid Withdraw Amount');
                                                        } else {
                                                            resolve(); // Validation passes
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                    }
                                ]} 
                                
                            >
                                <Input 
                                    placeholder="** Withdraw Amount (Minimum $100)" 
                                    className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                    onInput={handleOnInputWithdrawAmount}
                                />
                            </Form.Item> 
                        </Col>
                    </Row>
                    
                    
                    {/* Button Submit */}
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className='flex justify-center items-center'>
                                <Button 
                                    type="primary" 
                                    className="custom-primary-button h-[50px] px-12 py-5 rounded-xl"  
                                >
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default WithdrawAffiliatePortalFormModal;