import React, { useEffect, useState } from 'react';
import "./index.css";
import {
    Form,
    Input,
    Select,
    DatePicker,
    Image,
    Modal,
    Upload,
} from "antd";
import {
    EyeInvisibleOutlined,
    EyeOutlined,
    // LockOutlined,
    // UserOutlined,
    CheckOutlined,
    ExclamationCircleOutlined,
    UploadOutlined,
  } from "@ant-design/icons";
import PageHeader from "../../Components/common/pageHeader"; 
import { Button } from "Components/ui/button";
import { useAuth } from "context/AuthContext";
import { 
    getProfile, 
    createProfile,
    updateProfile,
    passwordChange,
    getUserVerification,
    fileUpload,
    submitUserVerification,
} from "../../API";
import dayjs from 'dayjs';

// https://tfapi-dev.tradersflow.co/api/v1/verification 
// 127.0.0.1:3000/api/v1/verification

function Profile() { 
    const [activeTab, setActiveTab] = useState('tabs-personal-information');
    const [formPersonalInformation] = Form.useForm();
    const [formAccountInformation] = Form.useForm();
    const [formSubmitVerification] = Form.useForm();
    const [formPasswordChange] = Form.useForm();
    const { currentUser } = useAuth(); 
    
    // const [loading, setLoading] = useState(false);
    // const [loadings, setLoadings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
 

    const [profileInfo, setProfileInfo] = useState({});
    const [userVerificationInfo, setUserVerificationInfo] = useState({});

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); 

    const { Option } = Select;
    const [modal, modalContextHolder] = Modal.useModal();

    const [isVerification, setIsVerification] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState(false);
    const [isVisibleVerificationModal, setIsVisibleVerificationModal] = useState(false);
    
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    }; 

    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
    };

    /*******************************************************/
    /* Personal Information */
    /*******************************************************/
    const onSubmitPersonalInformation = async(values) => {
    }
      
    /*******************************************************/
    /* Account Information */
    /*******************************************************/
    const handleOnInputAge = (e) => {
        let input = e.target.value;
        input = input.replace(/\D/g, '');
        e.target.value = input;
    }

    const [birthDayDate, setBirthDayDate] = useState(null);
    const handleDatePicker = (date, dateString) => {
        setBirthDayDate(dateString);
    }

    const onSubmitAccountInformation = async(values) => {
        const birthDay = values.birthDay;
        const formattedDate = birthDay.format('YYYY-MM-DD');
        values.formattedDate = formattedDate;

        modal.confirm({
            title: 'Confirmation',
            icon: <ExclamationCircleOutlined />,
            content: 'Confirm to submit your account information?',
            okText: 'Confirm',
            cancelText: 'Cancel', 
            centered: true, 
            okButtonProps: { className: "modal-ok-button" },
            onOk(){
                handleSubmitAccountInformation(values);
            },
        });
    }

    const handleSubmitAccountInformation = async(values) => {
        let user_profile = profileInfo?.profile ?? {};
        let profile_id = user_profile?.id ?? "";
        if (user_profile === null || (Object.keys(user_profile).length === 0 && user_profile.constructor === Object)) {
            try {
                let data = await createProfile(values);
                profile_id = data.profile.id;
            } catch (error) {
                console.error('Error create profile:', error);
            }
        }

        try {
            let updateRes = await updateProfile(values, profile_id);
            let updateResStatus = updateRes?.profile?.status ?? 0;
            if (updateResStatus == 1) {
                modal.success({
                    title: 'Personal Area Information has been updated!',
                    content: (
                        <div>
                            <div>Please proceed to submit your verification</div>
                        </div>
                    ),
                    okText: 'OK',
                    centered: true, 
                    okButtonProps: { className: "modal-ok-button" },
                    onOk(){
                        fetchDataAndSetProfileData();
                    },
                });
            } else {
                modal.error({
                    title: 'Error!',
                    content: (
                        <div>
                            <div>Personal Area Information failed updated!</div>
                        </div>
                    ),
                    okText: 'OK',
                    centered: true, 
                    okButtonProps: { className: "modal-ok-button" },
                    onOk(){
                    },
                });
            }
        } catch (error) {
            console.error('Error update profile:', error);
        }
    };

    /*******************************************************/
    /* Verification */
    /*******************************************************/
    const handleVerification = () => {
        setIsVisibleVerificationModal(true);
    }

    const handleSubmitVerification = () => {
        // setIsLoading(true);
        formSubmitVerification.submit();
    }

    const handleCancelSubmitVerification = () => {
        setIsVisibleVerificationModal(false);
    };

    const onSubmitVerification = async (values) => {
        let postVerificationFilePathObj = {};
        let errorOccurred = false;
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                try {
                    let inputFileUpload = values[key][0]["originFileObj"];
                    let res = await fileUpload(inputFileUpload);
                    if (res) {
                        let isSuccess = res?.success ?? false;
                        if (isSuccess) {
                            let tempObj = {
                                [key]: res.data,
                            }
                            postVerificationFilePathObj = { ...postVerificationFilePathObj, ...tempObj };

                            if (key == "address_front") {
                                tempObj = {
                                    "address_back": res.data,
                                }
                                postVerificationFilePathObj = { ...postVerificationFilePathObj, ...tempObj };
                            }
                        } else {
                            errorOccurred = true; 
                        }
                    }
                } catch(error){
                    errorOccurred = true;
                    modal.error({
                        title: 'Error!',
                        content: (
                            <div>
                                <div>{error?.response?.data?.message ?? "Upload File Failed"}</div>
                            </div>
                        ),
                        okText: 'OK',
                        centered: true, 
                        okButtonProps: { className: "modal-ok-button" },
                        onOk(){
                        },
                    });
                    console.error('Error upload verification file:', error);
                }
            }

            if (errorOccurred) {
                break; // Exit the loop if an error occurred
            }
        }

        if (!errorOccurred) { 
            // submit verificaiton 
            try {
                let result = await submitUserVerification(postVerificationFilePathObj);
                if (result) {
                    handleFormReset("formSubmitVerification");
                    setIsVisibleVerificationModal(false);
                    modal.success({
                        title: 'Submit Verification Successfully!',
                        content: (
                            <div>
                                <div>Thank you, your account is pending for verification! It takes 3 - 5 working days process. Please be patient on verification result</div>
                            </div>
                        ),
                        okText: 'OK',
                        centered: true, 
                        okButtonProps: { className: "modal-ok-button" },
                        onOk(){
                            setIsVerification(true);
                        },
                    });
                }
            } catch(error){
                modal.error({
                    title: 'Error!',
                    content: (
                        <div>
                            <div>{error?.response?.data?.message ?? "Submit Verification Failed"}</div>
                        </div>
                    ),
                    okText: 'OK',
                    centered: true, 
                    okButtonProps: { className: "modal-ok-button" },
                    onOk(){
                    },
                });
                console.error('Error submit verification:', error);
            }
        }
    }

    /*******************************************************/
    /* Password Change */
    /*******************************************************/
    const handleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const onSubmitChangePassword = (values) => {
        // setIsLoading(!isLoading);
        modal.confirm({
            title: 'Confirmation',
            icon: <ExclamationCircleOutlined />,
            content: 'Confirm to change your password?',
            okText: 'Confirm',
            cancelText: 'Cancel', 
            centered: true, 
            okButtonProps: { className: "modal-ok-button" },
            onOk(){
                handlePasswordChangeApi(values);
            },
        });
    };

    const handlePasswordChangeApi = async (values) => {
        try {
            let res = await passwordChange(values);
            let updateResStatus = res?.user?.status ?? 0;
            if (updateResStatus == 1) {
                modal.success({
                    title: 'Update Successfully!',
                    content: (
                        <div>
                            <div>Password has been updated!</div>
                        </div>
                    ),
                    okText: 'OK',
                    centered: true, 
                    okButtonProps: { className: "modal-ok-button" },
                    onOk(){
                        handleFormReset("formPasswordChange");
                    },
                });
            }
        } catch (error) {
            modal.error({
                title: 'Error!',
                content: (
                    <div>
                        <div>{error?.response?.data?.message ?? "Update Failed"}</div>
                    </div>
                ),
                okText: 'OK',
                centered: true, 
                okButtonProps: { className: "modal-ok-button" },
                onOk(){
                },
            });
            console.error('Error create profile:', error);
        } 
    } 

    /*******************************************************/

    const handleFormReset = (form) => {
        switch(form){
            case "formPasswordChange": 
                formPasswordChange.resetFields(); 
                break;
            case "formSubmitVerification": 
                formSubmitVerification.resetFields(); 
                break;
        }
    }

    const tabs=[
        {
            target_id: "tabs-personal-information",
            label: "Personal Information",
        }, 
        {
            target_id: "tabs-account-information",
            label: "Account Information",
        }, 
        {
            target_id: "tabs-verification",
            label: "Verification",
        }, 
        {
            target_id: "tabs-password-change",
            label: "Password Change",
        },
    ]; 

    const fetchDataAndSetProfileData = async () => {
        try {
            const data = await getProfile();
            setProfileInfo(data);
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    const fetchDataAndSetUserVerification = async () => {
        try {
            const data = await getUserVerification();
            setUserVerificationInfo(data);
        } catch (error) {
            console.error('Error fetching user verification data:', error);
        }
    }

    useEffect(() => {
        fetchDataAndSetProfileData(); 
        fetchDataAndSetUserVerification();
    }, []);

    useEffect(() => {
        formPersonalInformation.setFieldsValue({
            first_name: profileInfo?.user?.first_name ?? "-",
            last_name: profileInfo?.user?.last_name ?? "-", 
            email_address: profileInfo?.user?.email ?? "-", 
            phone_number:  profileInfo?.user?.mobile ?? "-",
            address: profileInfo?.profile?.address,
            city: profileInfo?.user?.city ?? "-",
            zip_code: profileInfo?.profile?.zip,
            state: profileInfo?.user?.state ?? "-", 
            country: profileInfo?.user?.country ?? "-", 
        }); 

        let dateDob = profileInfo?.profile?.dob ?? "";
        let newDateDob = dayjs();
        if (dateDob.length > 0) {
            let tempDateDob = dayjs(dateDob);
            newDateDob = tempDateDob;
        }
        formAccountInformation.setFieldsValue({
            gender: profileInfo?.profile?.gender ?? "mr",
            age: profileInfo?.profile?.age ?? "",
            birthDay: newDateDob,
            address: profileInfo?.profile?.address ?? "",
            zip_code: profileInfo?.profile?.zip ?? "",
        });
    }, [profileInfo]);

    useEffect(() => {
        if (typeof userVerificationInfo === 'object' && Object.keys(userVerificationInfo).length > 0) {
            let verifications = userVerificationInfo?.verifications ?? [];
            if (Array.isArray(verifications) && verifications.length > 0) {
                setIsVerification(true);
                setVerificationStatus(!!verifications[0].status);
            }
        }  
    }, [userVerificationInfo]);

    return (
        <>
            <div className="Payout-page">
                <div className="header">
                    <div className="left-section">
                    <PageHeader
                        title={'User Profile'}
                    />
                    </div>
                </div>

                {/* Tabs navigation */} 
                <ul 
                    className="mb-5 flex list-none flex-row flex-wrap border-b-0 ps-0"
                    role="tablist"
                >
                    {tabs.map((data,i)=>{
                        return (
                            <li key={i} role="presentation" className="flex-grow basis-0 group relative m-1 flex justify-center items-center">
                                <div
                                    onClick={() => handleTabClick(data.target_id)}
                                    className={`block border-x-0 border-b-0 border-t-0 border-transparent cursor-pointer px-7 pb-3.5 pt-4 text-md font-medium leading-tight text-neutral-500 ${
                                    activeTab === data.target_id
                                        ? 'text-[#e53a49]'
                                        : 'hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent'
                                    } group-hover:text-[#e53a49] group-hover:font-semibold`}
                                    role="tab"
                                >
                                    {data.label}
                                </div>
                                <span
                                    className={`block absolute bottom-0 left-0 right-0 h-[0.15rem] bg-[#e53a49] transform origin-center scale-x-0 transition-transform ease-in-out duration-500 group-hover:scale-x-100 ${activeTab === data.target_id ? 'scale-x-100' : ''}`}
                                ></span>
                            </li>
                        )
                    })}
                </ul>  
                {/* Tab Content */}
                <div className="mb-6">
                    {/* Personal Information */}
                    <div
                        className={`${
                            activeTab === 'tabs-personal-information'
                              ? 'opacity-100 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block'
                              : 'hidden opacity-0 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block'
                          }`}
                        id="tabs-personal-information"
                        role="tabpanel"
                        aria-labelledby="tabs-personal-information"
                    >   
                        <div className="py-5">
                            <div className="flex justify-start items-center mb-12">
                                <div className="avatar-wrapper">
                                    <img
                                        className="h-10 w-10 rounded-full bg-gray-50"
                                        src="https://images.unsplash.com/photo-1504714146340-959ca07e1f38?q=80&w=3400&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                        alt=""
                                    />
                                </div>
                                <div className="ml-3">
                                    <div className="font-bold text-xl">{currentUser?.name}</div>
                                    <div>{currentUser?.email}</div>
                                </div>
                            </div>

                            <Form
                                form={formPersonalInformation}
                                name="personal_information"
                                onFinish={(values) => onSubmitPersonalInformation(values, formPersonalInformation)}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                requiredMark={false}

                            >
                                <div className="mb-12 client-container">
                                    <div className="pb-3 font-bold text-lg">Client</div>
                                    <div className="bg-white rounded-2xl p-7">
                                        <div className="grid gap-5 grid-cols-1 xl:grid-cols-2">
                                            <Form.Item
                                                name="first_name" 
                                                className='mb-0'
                                                label={<span>First Name<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "First Name is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="First Name" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item> 
                                            
                                            <Form.Item
                                                name="last_name"
                                                className='mb-0'
                                                label={<span>Last Name<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Last Name is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="Last Name" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>  
                                </div>

                                <div className="contact-info-container">
                                <div className="pb-3 font-bold text-lg">Contact Info</div>
                                    <div className="bg-white rounded-2xl p-7">
                                        <div className="grid gap-5 grid-cols-1 xl:grid-cols-2">
                                            <Form.Item
                                                name="email_address"
                                                className='mb-0'
                                                label={<span>Email Address<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Email Address is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="Email Address" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item>
                                            
                                            <Form.Item
                                                name="phone_number" 
                                                className='mb-0'
                                                label={<span>Phone Number<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Phone Number is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="Phone Number" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="address" 
                                                className='mb-0'
                                                label={<span>Address<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Address is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="Address" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="city" 
                                                className='mb-0'
                                                label={<span>City<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "City is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="City" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="zip_code" 
                                                className='mb-0'
                                                label={<span>Zip Code<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Zip Code is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="Zip Code" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="state"
                                                className='mb-0'
                                                label={<span>State<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "State is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="State" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item> 

                                            <Form.Item
                                                name="country"
                                                className='mb-0'
                                                label={<span>Country<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Country is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="Country" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item> 
                                        </div>
                                    </div>
                                </div>
                                
                            </Form>
                        </div>
                    </div>
                    
                    {/* Account Information */}
                    <div
                        className={`${
                            activeTab === 'tabs-account-information'
                              ? 'opacity-100 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block'
                              : 'hidden opacity-0 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block'
                          }`}
                        id="tabs-account-information"
                        role="tabpanel"
                        aria-labelledby="tabs-account-information"
                    >
                        <div className="py-5">
                            <Form
                                form={formAccountInformation}
                                name="account_information"
                                onFinish={(values) => onSubmitAccountInformation(values, formAccountInformation)}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                requiredMark={false}
                                // initialValues={{
                                //     birthDay: dayjs(),
                                // }}
                            >
                                <div className="account-info-container">
                                <div className="pb-3 font-bold text-lg">Account Information</div>
                                    <div className="bg-white rounded-2xl p-7">
                                        <div className="grid gap-5 grid-cols-1 xl:grid-cols-2">
                                            <Form.Item 
                                                name="gender" 
                                                className="mb-0"
                                                label={<span>Gender<span style={{ color: 'red' }}> *</span></span>}
                                                // initialValue="mr"
                                                rules={[{ 
                                                    required: true,
                                                    message: "Gender is required",
                                                }]}
                                            >
                                                <Select
                                                    className="h-[55px] w-full rounded-[15px]  hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                    allowClear 
                                                    // bordered={false}
                                                    variant="outlined"
                                                >
                                                    <Option value="mr">Mr.</Option>
                                                    <Option value="ms">Ms./Mrs.</Option>
                                                </Select>
                                            </Form.Item>
                                            
                                            <Form.Item
                                                name="age" 
                                                className='mb-0'
                                                label={<span>Age<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Age is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="E.g. 25" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                    onInput={handleOnInputAge}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="birthDay" 
                                                className='mb-0'
                                                label={<span>Birthday<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Birthday is required",
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    className="w-full h-[55px] bg-[#F6F6F6] border border-gray-300 rounded-[15px] hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent hover:bg-[#F6F6F6]" 
                                                    onChange={handleDatePicker}  
                                                    placeholder="Birthday" 
                                                    format={'DD/MM/YYYY'}
                                                    showToday={true}
                                                />
                                            </Form.Item>
                                            
                                            <Form.Item
                                                name="address" 
                                                className='mb-0'
                                                label={<span>Address<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Address is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="Address" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="zip_code" 
                                                className='mb-0'
                                                label={<span>Zip Code<span style={{ color: 'red' }}> *</span></span>} 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Zip Code is required",
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="Zip Code" 
                                                    className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center mt-5">
                                    <Button
                                        type="submit"
                                        // loading={isLoading ? true : false}
                                        // disabled={isLoading ? true : false}
                                        className="w-auto px-5 py-3 bg-primary-gradient text-white border-0 rounded-2xl text-sm font-medium shadow-none hover:bg-primary-gradient-hover"
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>

                    {/* Verification */}
                    <div
                        className={`${
                            activeTab === 'tabs-verification'
                              ? 'opacity-100 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block'
                              : 'hidden opacity-0 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block'
                          }`}
                        id="tabs-verification"
                        role="tabpanel"
                        aria-labelledby="tabs-verification"
                    >
                        {!isVerification && (
                            <div className="py-5">
                                <div className="text-lg font-semibold mb-4">Opps, you don't have any funded account now !</div>
                                <div className="text-lg mb-3">KYC Purpose:</div>
                                <div>1. Withdraw</div>
                                <div className="grid gap-5 grid-cols-1 md:grid-cols-2 p-4">
                                    <div>
                                    <div className="text-center mb-2">Step 1:</div>
                                        <div className="w-9/12 m-auto">
                                            <Image
                                                src="/images/step1.799bb00b.png" 
                                                className="w-full"
                                                alt="step 1"
                                                preview={false}
                                            />
                                        </div>
                                        <div className="text-center">1. Success any challenge (Trial not counted as challenge), you will earn a funded account</div>
                                    </div>
                                    <div>
                                        <div className="text-center mb-2">Step 1:</div>
                                        <div className="w-9/12 m-auto">
                                            <Image
                                                src="/images/step2.f9a82967.png" 
                                                className="w-full"
                                                alt="step 2"
                                                preview={false}
                                            />
                                        </div>
                                        <div className="text-center">2. Once you earned a funded account, you need to complete KYC verification to unlock Withdraw.</div>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center mt-5">
                                    <Button
                                        // loading={isLoading ? true : false}
                                        // disabled={isLoading ? true : false}
                                        className="w-auto px-5 py-3 bg-primary-gradient text-white border-0 rounded-2xl text-sm font-medium shadow-none hover:bg-primary-gradient-hover" 
                                        onClick={handleVerification}
                                    >
                                        Verify Now
                                    </Button>
                                </div>
                            </div>
                        )}
                        {isVerification && (<div>
                            <div 
                                className='text-sm text-red-600'
                            >
                                Your KYC verification is pending! It takes 3 - 5 working days to proceed. Please be patient on verification result 
                                {verificationStatus && (
                                    <span className='ml-3 py-1 px-2 whitespace-nowrap rounded-full bg-green-400 text-[0.68rem"]'>Verified</span>
                                )}
                                {!verificationStatus && (
                                    <span className='ml-3 py-1 px-2 whitespace-nowrap rounded-full bg-yellow-400 text-[0.68rem]'>Not Verified</span>
                                )}
                            </div>
                            <div className='mt-6'>
                                <div className='grid gap-4 grid-cols-1 md:grid-cols-2'>
                                    <div className='w-full text-center '>
                                        <img
                                            src={userVerificationInfo?.verifications?.[0]?.id_front}
                                            className="w-full pointer-events-none"
                                            alt="ID Front"
                                            // preview={false}
                                        />
                                        <div className='mt-3 flex justify-center'>
                                            <div className="px-3 text-xs border-r border-dotted border-black">
                                                {userVerificationInfo?.verifications?.[0]?.created_at ? 
                                                    dayjs(userVerificationInfo.verifications[0].created_at).format("YYYY-MM-DD hh:mmA") :
                                                    "N/A" // Default value
                                                }
                                            </div>
                                            <div className='px-3 text-xs border-r border-dotted border-black'>
                                                {userVerificationInfo?.verifications?.[0]?.status !== undefined ? 
                                                    (userVerificationInfo?.verifications?.[0]?.status === 1 ? "Verified" : "Pending") :
                                                    "N/A" // Default value
                                                }
                                            </div>
                                            <div className='px-3 text-xs'>Front</div>
                                        </div>
                                    </div>
                                    <div className='w-full text-center'>
                                        <img
                                            src={userVerificationInfo?.verifications?.[0]?.id_back}
                                            className="w-full pointer-events-none"
                                            alt="ID Back"
                                            preview={false}
                                        />
                                        <div className='mt-3 flex justify-center'>
                                            <div className="px-3 text-xs border-r border-dotted border-black">
                                                {userVerificationInfo?.verifications?.[0]?.created_at ? 
                                                    dayjs(userVerificationInfo.verifications[0].created_at).format("YYYY-MM-DD hh:mmA") :
                                                    "N/A" // Default value
                                                }
                                            </div>
                                            <div className='px-3 text-xs border-r border-dotted border-black'>
                                                {userVerificationInfo?.verifications?.[0]?.status !== undefined ? 
                                                    (userVerificationInfo?.verifications?.[0]?.status === 1 ? "Verified" : "Pending") :
                                                    "N/A" // Default value
                                                }
                                            </div>
                                            <div className='px-3 text-xs'>Back</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>

                    {/* Password Change */}
                    <div
                        className={`${
                            activeTab === 'tabs-password-change'
                              ? 'opacity-100 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block'
                              : 'hidden opacity-0 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block'
                          }`}
                        id="tabs-password-change"
                        role="tabpanel"
                        aria-labelledby="tabs-password-change"
                    >
                        <Form
                            form={formPasswordChange}
                            name="password_change"
                            onFinish={(values) => onSubmitChangePassword(values, formPasswordChange)}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            requiredMark={false}
                        >
                            <div className="bg-white rounded-2xl p-7 ">
                                <Form.Item
                                    name="old_password"
                                    label={<span>Old Password<span style={{ color: 'red' }}> *</span></span>} 
                                    rules={[
                                        {
                                            required: true,
                                            message: "Old Password is required",

                                        },
                                    ]}
                                >
                                    <Input
                                        type={showOldPassword ? "text" : "password"}
                                        placeholder="Old Password" 
                                        className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                                        suffix={
                                            <span
                                            onClick={handleOldPasswordVisibility}
                                            className="password-toggle "
                                            >
                                            {showOldPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                            </span>
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="new_password"
                                    label={<span>New Password<span style={{ color: 'red' }}> *</span></span>} 
                                    rules={[
                                        {
                                            required: true,
                                            message: "New Password is required",
                                        },
                                    ]}
                                >
                                    <Input
                                        type={showNewPassword ? "text" : "password"}
                                        placeholder="New Password" 
                                        className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                                        suffix={
                                            <span
                                            onClick={handleNewPasswordVisibility}
                                            className="password-toggle"
                                            >
                                            {showNewPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                            </span>
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="confirm_password"
                                    label={<span>Confirm Password<span style={{ color: 'red' }}> *</span></span>} 
                                    rules={[
                                        {
                                            required: true,
                                            message: "Confirm Password is required",
                                        },
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('new_password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Passwords do not match');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        type={showConfirmPassword ? "text" : "password"}
                                        placeholder="Confirm Password" 
                                        className="input-text h-[55px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                                        suffix={
                                            <span
                                                onClick={handleConfirmPasswordVisibility}
                                                className="password-toggle "
                                            >
                                            {showConfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                            </span>
                                        }
                                    />
                                </Form.Item>
                            </div>
                            <div className="flex justify-center items-center mt-5">
                                <Button
                                    // loading={isLoading ? true : false}
                                    // disabled={isLoading ? true : false}
                                    className="w-auto px-5 py-3 bg-primary-gradient text-white border-0 rounded-2xl text-sm font-medium shadow-none hover:bg-primary-gradient-hover"
                                >
                                    Change Password
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
                
            </div>
            {modalContextHolder}
            {isVisibleVerificationModal && ( <Modal
                open={isVisibleVerificationModal}
                title={
                    <h2 className="font-semibold text-lg">Submit Your Information</h2>
                }
                // className='w-4/5 lg:w-3/5'
                className='verification-modal' 
                onOk={handleSubmitVerification}
                onCancel={handleCancelSubmitVerification}
                maskClosable={false}
                footer={[
                    <div key="footer" style={{ textAlign: 'center', marginTop: '1.5rem'}}>
                        <Button 
                            type="primary" 
                            // loading={"true"} 
                            className="modal-ok-button"
                            onClick={() => {
                                handleSubmitVerification();
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                ]}
            >
                <Form
                    form={formSubmitVerification}
                    name="submit_verification"
                    onFinish={(values) => onSubmitVerification(values, formSubmitVerification)}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    requiredMark={false}
                >
                    <hr />
                    <div className="mt-2">
                        <h3 className="text-base font-medium mb-3">Identification (Passport or National ID)</h3>
                        <div className="grid gap-4 grid-cols-2 xl:grid-cols-4 px-4">
                            <div className='w-full'>
                                <Image
                                    src="/images/verification_id_1_mustNotBlur.png" 
                                    className="w-full pointer-events-none"
                                    preview={false}
                                />
                            </div>
                            <div className="w-full">
                                    <Image
                                    src="/images/verification_id_2_mustNotCut.png" 
                                    className="w-full pointer-events-none"
                                    preview={false}
                                />
                            </div>
                            <div className="w-full">
                                <Image
                                    src="/images/verification_id_3_mustColor.png" 
                                    className="w-full pointer-events-none"
                                    preview={false}
                                />
                            </div>
                            <div className="w-full">
                                <Image
                                    src="/images/verification_id_4_goodExampleId.png" 
                                    className="w-full pointer-events-none"
                                    preview={false}
                                />
                            </div>
                        </div>



                        {/* <Form.Item
                            name="fileInput"
                            label="File Input"
                            rules={[
                            { required: true, message: 'Please select a file' }
                            ]}
                        > */}
                            {/* <input type="file" id="testing" name="testing" /> */}
                        {/* </Form.Item> */}

                        

                        <div className="text-sm font-medium mt-2 mb-4">Front and back of your passport proof</div>
                        <div className="grid gap-0 lg:gap-4 grid-cols-1 lg:grid-cols-2">
                            <div>
                                <Form.Item
                                    name="id_front"
                                    label="Passport Front"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    extra=".jpg, .png, pdf, max 10 MB" 
                                    rules={[
                                        {
                                            required: true,
                                            message: "Passport Front is required",
                                        },
                                        {
                                            validator: async (_, fileList) => {
                                                const file = fileList ? fileList[0] : undefined; // Assuming it's a single file upload
                
                                                if (!file) {
                                                    return false;
                                                    // return Promise.reject(new Error("Please upload a file"));
                                                }
                                                // Check file type and size
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                                const isPdf = file.type === 'application/pdf';
                                                const isLt10M = file.size / 1024 / 1024 < 10;
                                
                                                if (!isJpgOrPng && !isPdf) {
                                                    return Promise.reject(new Error("You can only upload JPG/PNG/PDF file!"));
                                                }
                                
                                                if (!isLt10M) {
                                                    return Promise.reject(new Error("Image must be smaller than 10MB!"));
                                                }
                                
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <Upload 
                                        name="file_id_front" 
                                        beforeUpload={() => {
                                            return false;
                                        }}
                                        maxCount={1} 
                                        listType="picture"
                                    >
                                        <Button type="button" icon={<UploadOutlined />}>Click to upload</Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    name="id_back"
                                    label="Passport Back"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    extra=".jpg, .png, pdf, max 10 MB" 
                                    rules={[
                                        {
                                            required: true,
                                            message: "Passport Front is required",
                                        },
                                        {
                                            validator: async (_, fileList) => {
                                                const file = fileList ? fileList[0] : undefined; // Assuming it's a single file upload
                                               
                                                if (!file) {
                                                    return false;
                                                    // return Promise.reject(new Error("Please upload a file"));
                                                }
                                                // Check file type and size
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                                const isPdf = file.type === 'application/pdf';
                                                const isLt10M = file.size / 1024 / 1024 < 10;
                                
                                                if (!isJpgOrPng && !isPdf) {
                                                    return Promise.reject(new Error("You can only upload JPG/PNG/PDF file!"));
                                                }
                                
                                                if (!isLt10M) {
                                                    return Promise.reject(new Error("Image must be smaller than 10MB!"));
                                                }
                                
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <Upload 
                                        name="file_id_back" 
                                        beforeUpload={() => false} 
                                        maxCount={1}
                                        accept=".jpg,.png,.pdf"
                                        listType="picture"
                                    >
                                        <Button type="button" icon={<UploadOutlined />}>Click to upload</Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="mt-3">
                        <h3 className="text-base font-medium mb-3">Proof of Address</h3>
                        <div className="grid gap-4 grid-cols-2 xl:grid-cols-4 px-4">
                            <div className='w-full'>
                                <Image
                                    src="/images/verification_poa_1_mustNotCutAddress.png" 
                                    className="w-full pointer-events-none"
                                    preview={false}
                                />
                            </div>
                            <div className="w-full">
                                <Image
                                    src="/images/verification_poa_2_mustNotblurAddress.png" 
                                    className="w-full pointer-events-none"
                                    preview={false}
                                />
                            </div>
                            <div className="w-full">
                                <Image
                                    src="/images/verification_poa_3_mustBeValid.png" 
                                    className="w-full pointer-events-none"
                                    preview={false}
                                />
                            </div>
                            <div className="w-full">
                                <Image
                                    src="/images/verification_poa_4_goodExampleAddress.png" 
                                    className="w-full pointer-events-none"
                                    preview={false}
                                />
                            </div>
                        </div>
                        <div className="text-sm font-medium mt-2 mb-4">Upload your address proof</div>
                        <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
                            <div>
                                <Form.Item
                                    name="address_front"
                                    label="Address"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    extra=".jpg, .png, pdf, max 10 MB" 
                                    rules={[
                                        {
                                            required: true,
                                            message: "Passport Front is required",
                                        },
                                        {
                                            validator: async (_, fileList) => {
                                                const file = fileList ? fileList[0] : undefined; // Assuming it's a single file upload
                                               
                                                if (!file) {
                                                    return false;
                                                    // return Promise.reject(new Error("Please upload a file"));
                                                }
                                                // Check file type and size
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                                const isPdf = file.type === 'application/pdf';
                                                const isLt10M = file.size / 1024 / 1024 < 10;
                                
                                                if (!isJpgOrPng && !isPdf) {
                                                    return Promise.reject(new Error("You can only upload JPG/PNG/PDF file!"));
                                                }
                                
                                                if (!isLt10M) {
                                                    return Promise.reject(new Error("Image must be smaller than 10MB!"));
                                                }
                                
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <Upload 
                                        name="file_address_front" 
                                        beforeUpload={() => false}
                                        maxCount={1}
                                        accept=".jpg,.png,.pdf"
                                        listType="picture" 
                                    >
                                        <Button type="button" icon={<UploadOutlined />}>Click to upload</Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                            
                        </div>
                    </div>
                    <hr />
                </Form>
            </Modal> 
            )}
           
        </>
    );
}

export default Profile;