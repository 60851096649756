import axios from "axios";
const apiUrl = process.env.REACT_APP_BASE_URL;
const apiUrlV2 = process.env.REACT_APP_BASE_URL_V2;
const token = localStorage.getItem("token");
// import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();

const publicAPIs = [
  "/auth/login",
  "/auth/register",
  "/auth/code",
  "/auth/code/verify",
  "/auth/forgot_password",
  "/challenge/purchase/step1",
  "/allchallenge",
  "/payment_methods_with_currencies",
  "/promocode",
];

// Create an Axios instance
const api = axios.create({
  baseURL: apiUrl, // Your API base URL
});

// Add an interceptor to modify requests
api.interceptors.request.use((config) => {
  // Get the token from localStorage
  const token = localStorage.getItem("token");

  const routepath = config.url
    .replace(config.baseURL, "")
    .replace(apiUrlV2, "");

  const isPublicApi = (routePath) =>
    publicAPIs.some((currApi) => routePath.startsWith(currApi));

  console.log(
    routepath,
    "HasToken?: ",
    !!token,
    "Protected?: ",
    !publicAPIs.includes(routepath)
  );

  // If a token exists, add it to the Authorization header
  // if (token && !publicAPIs.includes(routepath)) {
  if (token && !isPublicApi(routepath)) {
    config.headers.Authorization = `Bearer ${token}`;
    // } else if(!publicAPIs.includes(routepath)){
  } else if (!isPublicApi(routepath)) {
    console.log("invalid token, reject");
    localStorage.removeItem("token");
    window?.location?.replace?.("/auth/signin");
    return;
  }

  return config;
});

// Add an interceptor to handle responses
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expired or not valid
      // Redirect to the login page
      // router.push('/login');

      console.log("invalid auth, reject");
      localStorage.removeItem("token");
      window?.location?.replace?.("/auth/signin");
    }
    return Promise.reject(error);
  }
);

export const getOrders = () => {
  return fetch("https://dummyjson.com/carts/1").then((res) => res.json());
};

export const getRevenue = () => {
  return fetch("https://dummyjson.com/carts").then((res) => res.json());
};

// Login api
export const Login = async (values) => {
  try {
    const response = await api.post(`${apiUrl}/auth/login`, {
      email: values.email,
      password: values.password,
    });

    const { user, jwt } = response.data.data;
    const token = jwt.token;

    return { user, token };
  } catch (error) {
    throw error;
  }
};

//REGISTER API
export const Register = async (values) => {
  try {
    console.log("First Name:", values.firstName);
    console.log("Last Name:", values.lastName);
    console.log("Country:", values.country);
    console.log("State:", values.state);
    console.log("City:", values.city);
    console.log("Email:", values.email);
    console.log("Mobile:", values.mobile);
    console.log("Password:", values.password);
    console.log("Confirm Password:", values.confirmPassword);
    console.log("Verification Code:", values.auth_code);
    console.log("Affiliate_code:", values.affiliate_code);

    // const response = await api.post(`${apiUrl}/admin/auth/register`, {
    const response = await api.post(`${apiUrl}/auth/register`, {
      first_name: values.firstName,
      last_name: values.lastName,
      country: values.country,
      email: values.email,
      password: values.password,
      // confirmPassword:  values.confirmPassword,
      auth_code: values.auth_code,
      city: values.city,
      state: values.state,
      mobile: values.mobile,
      affiliate_code: values?.affiliate_code || "",
    });
    if (response.status === 200) {
      console.log("values", response.data);
    }
    return response.data;
  } catch (error) {
    console.error("An error occurred during Signup:", error);
    throw error;
  }
};

export const getInventory = () => {
  return fetch("https://dummyjson.com/products").then((res) => res.json());
};

export const getCustomers = () => {
  return fetch("https://dummyjson.com/users").then((res) => res.json());
};
export const getComments = () => {
  return fetch("https://dummyjson.com/comments").then((res) => res.json());
};

//ACCOUNT FETCHING API
export const fetchAccounts = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await api.get(`${apiUrl}/me/accounts`);
    console.log(response.data.accounts);

    if (response.status === 200) {
      return response.data.accounts;
    } else {
      console.error("Failed to fetch accounts:", response.data);
      return [];
    }
  } catch (error) {
    console.error("An error occurred while fetching accounts:", error);
    return [];
  }
};

//FETCH ONE ACCOUNT
export const fetchoneAccount = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await api.get(`${apiUrl}/accounts/${id}`);
    console.log(response);
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Failed to fetch accounts:", response.data);
      return [];
    }
  } catch (error) {
    console.error("An error occurred while fetching accounts:", error);
    return [];
  }
};

//Fetch matrix
export const fetchMatrix = async (id) => {
  const token = localStorage.getItem("token");
  console.log("id coming in fetchmetrix", id);

  try {
    const response = await api.get(`${apiUrl}/me/metrics?account_login=${id}`);
    if (response.status === 200) {
      console.log("the  api response for matrix for me ", response.data);

      return response.data;
    } else {
      console.error("Failed to fetch accounts:", response.data);
      return [];
    }
  } catch (error) {
    console.error("An error occurred while fetching accounts:", error);
    return [];
  }
};

//Fetch DaysLoss
export const getLoss = async (userId, challengePhaseId) => {
  const token = localStorage.getItem("token");

  try {
    const url = `${apiUrl}/permitted_loss?account_login=${userId}&challenge_phase_id=${challengePhaseId}`;
    const response = await api.get(url);
    console.log("the data fro losssing", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Fetch withdraws
export const fetchWithdrawData = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get(`${apiUrl}/withdraws`);
    if (response.status === 200) {
      console.log(response.data.withdraws.data);
      return response.data.withdraws.data;
    } else {
      console.error("Failed to fetch accounts:", response.data);
      return [];
    }
  } catch (error) {
    console.error("Error fetching withdrawal data:", error);
  }
};

export const UserEmailCode = async (values) => {
  try {
    console.log(values.email);
    const response = await api.post(`${apiUrl}/auth/code`, {
      email: values.email,
    });
    console.log(response);

    if (response.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error("Error during password reset:", error?.response?.data);
    throw error;
  }
};

export const UserOtpCode = async (values) => {
  try {
    const response = await api.post(`${apiUrl}/auth/code/verify`, {
      email: values.email,
      code: values.otp,
    });

    console.log("API Response:", response);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Unexpected response status:", response.status);
    }
  } catch (error) {
    console.error(
      "Error during OTP code verification:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const fetchOneUserWithdraw = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get(`${apiUrl}/admin/withdraws/${id}`);
    if (response.status === 200) {
      console.log("withdaraw", response.data.withdraw);
      return response.data.withdraw;
    } else {
      console.error("Failed to fetch accounts:", response.data);
      return [];
    }
  } catch (error) {
    console.error("Error fetching withdrawal data:", error);
  }
};
export const getAllChallenges = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get(`${apiUrl}/challenges`);
    if (response.status === 200) {
      return response.data.challenges;
    } else {
      console.error("Failed to fetch Challenges:", response.data);
      return [];
    }
  } catch (error) {
    console.error("Error fetching Challenges data:", error);
  }
};

export const ForgetPassword = async (values) => {
  try {
    const response = await api.post(`${apiUrl}/auth/forgot_password`, {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      password_confirmation: values.confirmPassword,
      auth_code: values.otp,
    });

    if (response.status === 200) {
      console.log("Password reset successful:", response.data);
    }
  } catch (error) {
    console.error("An error occurred during password reset:", error);
  }
};

export const getOpenOrderRecord = async (acc_id) => {
  try {
    const response = await api.get(
      `${apiUrl}/me/accounts/${acc_id}/mt5/open_orders`
    );
    if (response.status === 200) {
      console.log("open_orders", response.data);
      return response.data;
    }
    // } else {
    //   console.error("Failed to fetch accounts:", response.data);
    //   return [];
    // }
  } catch (error) {
    console.error("An error:", error);
  }
};

export const getClosedOrderRecord = async (acc_id) => {
  try {
    const response = await api.get(
      `${apiUrl}/me/accounts/${acc_id}/mt5/closed_orders`
    );
    if (response.status === 200) {
      console.log("closed_orders", response.data);
      return response.data;
    }
    // } else {
    //   console.error("Failed to fetch accounts:", response.data);
    //   return [];
    // }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const getClosedOrderRecordWithDate = async (
  acc_id,
  dateFrom,
  dateTo
) => {
  try {
    const response = await api.get(
      `${apiUrl}/me/accounts/${acc_id}/mt5/closed_orders?from_date=${dateFrom}&to_date=${dateTo}`
    );
    if (response.status === 200) {
      console.log("closed_orders", response.data);
      return response.data;
    }
    // } else {
    //   console.error("Failed to fetch accounts:", response.data);
    //   return [];
    // }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const getPerformance = async (acc_id, challenge_phase_id) => {
  try {
    const response = await api.get(
      `${apiUrl}/me/performance?account_id=${acc_id}&challenge_phase_id=${challenge_phase_id}`
    );
    if (response.status === 200) {
      console.log("closed_orders", response.data);
      return response.data;
    }
    // } else {
    //   console.error("Failed to fetch accounts:", response.data);
    //   return [];
    // }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const ChangeMasterPassword = async (password, acc_id) => {
  try {
    const response = await api.put(
      `${apiUrl}/me/accounts/${acc_id}/master_password`,
      {
        new_password: password,
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during password reset:", error);
    throw error;
  }
};

export const ChangeInvestorPassword = async (password, acc_id) => {
  try {
    const response = await api.put(
      `${apiUrl}/me/accounts/${acc_id}/investor_password`,
      {
        new_password: password,
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during password reset:", error);
    throw error;
  }
};

export const getProfile = async (values) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get(`${apiUrl}/me`);
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Failed to fetch Profile info:", response.data);
      return [];
    }
  } catch (error) {
    console.error("An error occurred during get profile info:", error);
    throw error;
  }
};

export const createProfile = async (values) => {
  try {
    const response = await api.post(`${apiUrl}/me/profiles`, {
      age: values.age,
      dob: values.formattedDate,
      gender: values.gender,
      address: values.address,
      zip: values.zip_code,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during create profile:", error);
    throw error;
  }
};

export const updateProfile = async (values, profile_id) => {
  try {
    const response = await api.put(`${apiUrl}/me/profiles/${profile_id}`, {
      age: values.age,
      dob: values.formattedDate,
      gender: values.gender,
      address: values.address,
      zip: values.zip_code,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during update profile:", error);
    throw error;
  }
};

export const passwordChange = async (values) => {
  try {
    const response = await api.post(`${apiUrl}/auth/change_password`, {
      password: values.old_password,
      new_password: values.new_password,
      new_password_confirmation: values.confirm_password,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during change password:", error);
    throw error;
  }
};

export const getUserVerification = async (values) => {
  try {
    const response = await api.get(`${apiUrl}/verification`);
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Failed to fetch user verification info:", response.data);
      return [];
    }
  } catch (error) {
    console.error(
      "An error occurred during get user verification info:",
      error
    );
    throw error;
  }
};

export const fileUpload = async (values) => {
  try {
    let formData = new FormData();
    formData.append("file", values);

    const response = await api.post(`${apiUrl}/files`, formData);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during upload file:", error);
    throw error;
  }
};

export const submitUserVerification = async (values) => {
  try {
    const response = await api.post(`${apiUrl}/verification`, {
      id_front: values.id_front,
      id_back: values.id_back,
      address_front: values.address_front,
      address_back: values.address_back,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during submit verification:", error);
    throw error;
  }
};

export const getAllChallengesV2 = async () => {
  try {
    //const token = localStorage.getItem("token");
    const response = await api.get(`${apiUrlV2}/allchallenge`, {
      // headers: {
      //   // Authorization: `Bearer ${token}`,
      // },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Failed to fetch All Challenges:", response.data);
      return [];
    }
  } catch (error) {
    console.error("Error fetching All Challenges data:", error);
  }
};

export const getAccountPayout = async (acc_id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get(
      `${apiUrlV2}/withdraws/stats?account_id=${acc_id}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Failed to fetch payout:", response.data);
      return [];
    }
  } catch (error) {
    console.error("Error fetching payout data:", error);
  }
};
export const submitChallengePurchaseCheckout = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const response = await api.post(`${apiUrlV2}/challenge/purchase/checkout`, {
      challenge: values?.challenge,
      addons: values?.addons,
      promocode: values?.promocode,
      address: values?.address,
      city: values?.city,
      state: values?.state,
      country: values?.country,
      zip: values?.zip,
      payment_method_id: values?.payment_method_id ?? "",
      currency_id: values?.currency_id ?? "",
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during submit purchase challenge:", error);
    throw error;
  }
};

export const submitChallengePurchaseStep1 = async (values) => {
  try {
    const response = await api.post(`${apiUrlV2}/challenge/purchase/step1`, {
      challenge: values.challenge,
      addons: values.addons,
      email: values.email,
      auth_code: values.auth_code,
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
      address: values.address,
      city: values.city,
      state: values.state,
      country: values.country,
      zip: values.zip,
      promocode: values.promocode,
      affiliate_code: values?.affiliate_code,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(
      "An error occurred during submit purchase challenge Step1:",
      error
    );
    throw error;
  }
};

export const submitWithdraw = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${apiUrl}/withdraws`,
      {
        amount: values?.amount ?? "",
        payment_method_id: values?.payment_method_id ?? "",
        account_id: values?.account_id ?? "",
        currency_id: values?.currency_id ?? "",
        recipient_address: values?.recipient_address ?? "",
        auth_code: values?.auth_code ?? "",
        competitor: values?.competitor ?? "",
        network_fee: values?.network_fee ?? "",
        // account_holder: values?.account_holder ?? "",
        // account_number: values?.account_number ?? "",
        // bank_name: values?.bank_name ?? "",
        wallet_address: values?.wallet_address ?? "",
        wallet_network: values?.wallet_network ?? "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during submit withdraws:", error);
    throw error;
  }
};

export const resetChallenge = async (values) => {
  try {
    const response = await api.post(`${apiUrl}/me/reset_challenge`, {
      challenge: values.challenge,
      payment_method_id: values.payment_method_id,
      attachment: values.attachment,
      account_login: values.account_login,
      addons: values.addons,
      promocode: values.promocode,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during reset challenge:", error);
    throw error;
  }
};

export const getActivePaymentMethods = async () => {
  try {
    const response = await api.get(
      `${apiUrl}/payment_methods`
      // {
      //   challenge_id: values.challenge_id,
      //   payment_method_id: values.payment_method_id,
      //   attachment: values.attachment,
      //   account_login: values.account_login,
      // }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during reset challenge:", error);
    throw error;
  }
};

export const fetchAccountById = async (acc_id) => {
  try {
    const response = await api.get(`${apiUrl}/accounts/${acc_id}`);
    if (response.status === 200) {
      console.log("account", acc_id, response.data);
      return response.data;
    }
    // } else {
    //   console.error("Failed to fetch accounts:", response.data);
    //   return [];
    // }
  } catch (error) {
    console.error("An error:", error);
  }
};

export const getOneTradingNote = async (deal, accountId) => {
  try {
    const response = await api.get(
      `${apiUrlV2}/tradingnotes/details?account_id=${accountId}&deal=${deal}`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during get One Trading Note:", error);
    throw error;
  }
};

export const createTradingNote = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${apiUrlV2}/tradingnotes/create`,
      {
        account_id: values?.account_id ?? "",
        deal: values?.deal ?? "",
        setup: values?.setup ?? "",
        mistake: values?.mistake ?? "",
        others: values?.others ?? "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during create trading note:", error);
    throw error;
  }
};

export const updateTradingNote = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${apiUrlV2}/tradingnotes/${values?.trading_note_id ?? ""}?account_id=${
        values?.account_id ?? ""
      }`,
      {
        setup: values?.setup ?? "",
        mistake: values?.mistake ?? "",
        others: values?.others ?? "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during create trading note:", error);
    throw error;
  }
};

export const fetchAccountTradingDays = async (acc_id) => {
  try {
    const response = await api.get(
      `${apiUrl}/trading_days?account_login=${acc_id}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error:", error);
  }
};

export const fetchAccountProfit = async (acc_id) => {
  try {
    const response = await api.get(
      `${apiUrlV2}/account_profit?account_login=${acc_id}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error:", error);
  }
};

export const getPAndLData = async (acc_id, challenge_phase_id) => {
  try {
    const response = await api.get(
      `${apiUrlV2}/daily_pnls?account_id=${acc_id}`
    );
    if (response.status === 200) {
      return response.data;
    }
    // } else {
    //   console.error("Failed to fetch accounts:", response.data);
    //   return [];
    // }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const getTradingNoteOrder = async (deal, accountId) => {
  try {
    const response = await api.get(
      `${apiUrlV2}/tradingnotes/order?account_id=${accountId}&deal=${deal}`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during get Trading Note Order:", error);
    throw error;
  }
};

export const getAffiliates = async () => {
  try {
    const response = await api.get(`${apiUrlV2}/affiliates`);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during get affiliates:", error);
    throw error;
  }
};

export const getAffiliatesPayoutTransactions = async () => {
  try {
    const response = await api.get(`${apiUrlV2}/affiliates/transactions`);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(
      "An error occurred during get affiliates payout transactions:",
      error
    );
    throw error;
  }
};

export const getAffiliatesEarning = async () => {
  try {
    const response = await api.get(`${apiUrlV2}/affiliates/earnings`);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during get affiliates earnings:", error);
    throw error;
  }
};

export const getAffiliatesEarningTransaction = async () => {
  try {
    const response = await api.get(
      `${apiUrlV2}/affiliates/earnings/transactions`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(
      "An error occurred during get affiliates earnings transaction:",
      error
    );
    throw error;
  }
};

export const getAffiliatesPayout = async () => {
  try {
    const response = await api.get(`${apiUrlV2}/affiliates/payouts`);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during get affiliates payouts:", error);
    throw error;
  }
};

export const submitAffiliatesPayout = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${apiUrlV2}/affiliates/payout`,
      {
        amount: values?.amount ?? 0,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during affiliates payout:", error);
    throw error;
  }
};

export const getPurchaseChallengeStatus = async (id) => {
  try {
    const response = await api.get(`${apiUrlV2}/transactions/${id}`);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(
      "An error occurred during get purchase challenge status:",
      error
    );
    throw error;
  }
};

// export const getPaymentMethodWithCurrencies = async (operation = "deposit") => {
//   try {
//     const response = await api.get(
//       `${apiUrl}/payment_methods_with_currencies?operation=${operation}`,
//       {}
//     );

//     if (response.status === 200) {
//       return response.data;
//     }

//   } catch (error) {
//     console.error("An error occurred during Find Payment Method with currencies:", error);
//     throw error;
//   }
// }
export const getPaymentMethodWithCurrencies = async (operation = "deposit") => {
  try {
    //const token = localStorage.getItem("token");
    const response = await api.get(
      `${apiUrl}/payment_methods_with_currencies?operation=${operation}`,
      {
        // headers: {
        //   // Authorization: `Bearer ${token}`,
        // },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(
      "An error occurred during Find Payment Method with currencies:",
      error
    );
  }
};

export const submitDeposits = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${apiUrl}/me/deposits`,
      {
        payment_method_id: values?.payment_method_id ?? "",
        currency_id: values?.currency_id ?? "",
        challenge: values?.challenge ?? "",
        addons: values?.addons ?? [],
        promocode: values?.promocode ?? "",
        attachment: values?.attachment ?? "",
        account_login: values?.account_login ?? "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during Post deposits:", error);
    throw error;
  }
};

export const getOneTradingNoteOrder = async (deal, accountId) => {
  // Type = close
  try {
    const response = await api.get(
      `${apiUrlV2}/tradingnotes/order/details?account_id=${accountId}&deal=${deal}`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during get Trading Note Order:", error);
    throw error;
  }
};

export const getOneTradingNotePosition = async (deal, accountId) => {
  // Type = open
  try {
    const response = await api.get(
      `${apiUrlV2}/tradingnotes/position/details?account_id=${accountId}&deal=${deal}`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during get Trading Note Order:", error);
    throw error;
  }
};

export const getUserCert = async (accountLogin) => {
  // Type = open
  try {
    let response;
    if (accountLogin) {
      response = await api.get(
        `${apiUrl}/me/certs?account_login=${accountLogin}`
      );
    } else {
      response = await api.get(`${apiUrl}/me/certs`);
    }

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during get User Cert:", error);
    throw error;
  }
};

export const checkPromoCode = async (promoCode) => {
  // V2 check promo code is public once (no auth)
  try {
    const response = await api.get(
      `${apiUrlV2}/promocode?promo_code=${promoCode}`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during check promo code:", error);
    throw error;
  }
};

export const postToProceedNextLevel = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${apiUrl}/account/proceed_next`,
      {
        account_login: values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("An error occurred during Proceed Next Level:", error);
    throw error;
  }
};

export const getAllAuthChallengesV2 = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get(`${apiUrlV2}/auth_allchallenge`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Failed to fetch All Challenges:", response.data);
      return [];
    }
  } catch (error) {
    console.error("Error fetching All Challenges data:", error);
  }
};
